import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

function WebAppManifest() {
    const location = useLocation();
    return (
        <Redirect
            to={{
                pathname: '/store',
                state: { from: location },
            }}
        />
    );
}

export default WebAppManifest;
